import React from 'react'

import DescTextModule from '../components/description-text-module'
import ImageTextModule from '../components/image-text-module'
import ModuleImageColumns from '../components/module-image-columns'
import PulloutTextModule from '../components/pullout-text-module'
import SlideshowModule from '../components/slideshow-module'
import FlexibleFooterModule from '../components/flexible-footer-module'
import HomepageColumn from '../components/homepage-column-module'
import RelatedPosts from '../components/related-posts'
import StaffMembers from '../components/staff-member-module'
import LargeCenterText from '../components/large-center-text-module'
import Expertise from '../components/expertise-module'
import Services from '../components/services-module'
import ProjectsArchive from '../components/projects-archive-module.js'
import FormText from '../components/form-text-module'
import MapModule from '../components/map'

const renderBlock = (param, el, i, page={}) => {
  let block = {
    'WordPressAcf_gallery' : (el, i) => <div key={i} {...el} />,
    'WordPressAcf_project_description_text_module' : (el, i) => <DescTextModule key={i} {...el} />,
    'WordPressAcf_image_text_module' : (el, i) => <ImageTextModule key={i} {...el} />,
    'WordPressAcf_module_image_columns': (el, i) => <ModuleImageColumns key={i} {...el} />,
    'WordPressAcf_pullout_text_module': (el, i) => <PulloutTextModule key={i} {...el} />,
    'WordPressAcf_slideshow_module': (el, i) => <SlideshowModule key={i} {...el} />,
    'WordPressAcf_flexible_footer_module': (el, i) => <FlexibleFooterModule key={i} {...el} />,
    'WordPressAcf_related_posts': (el, i) => <RelatedPosts key={i} {...el} />,
    'WordPressAcf_homepage_columns': (el, i) => <HomepageColumn key={i} {...el} />,
    'WordPressAcf_staff_member_module': (el, i) => <StaffMembers key={i} {...el} />,
    'WordPressAcf_large_center_text_module': (el, i) => <LargeCenterText key={i} {...el} />,
    'WordPressAcf_expertise_module': (el, i) => <Expertise key={i} {...el} />,
    'WordPressAcf_services_module': (el, i) => <Services key={i} {...el} />,
    'WordPressAcf_projects_archive_module': (el, i) => <ProjectsArchive key={i} {...el} />,
    'WordPressAcf_form_text_module': (el, i) => <FormText key={i} {...el} />,
    'WordPressAcf_map_module': (el, i) => <MapModule key={i} {...el} />,
  }[param]


  if (!block) return (<p>{ param }</p>)

  return block(el, i)
}

export default renderBlock
