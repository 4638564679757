import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Link from '../utils/link'

class HomepageColumn extends Component {

  _renderItem = (el, i) => {
    if (!el.post || !el.post.length) return
    let post = el.post[0]

    let props = {
      key: i,
      to: `/${post.type === 'post' ? 'news' : post.type}/${post.slug}`,
      className: `module__homepage-column__item module__homepage-column__item--size-${el.size}`,
    }

    let image = post.acf.feature_image ? post.acf.feature_image.localFile.childImageSharp.original : null

    return (
      <Link {...props}>
        <Fade bottom distance='40px'>
          <picture>
            { image &&
              <img src={image.src} alt={post.acf.feature_image.alt_text} width={image.width} height={image.height} />
            }
          </picture>
          <h5>{ post.type }</h5>
          <h4>{ post.title }</h4>
        </Fade>
      </Link>
    )
  }

  render() {
    return (
      <section className='module__homepage-column'>
        <div className='module__homepage-column__inner'>
          { this.props.items && this.props.items.map(this._renderItem) }
        </div>
      </section>
    )
  }
}

export default HomepageColumn
