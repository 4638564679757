import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Link from '../utils/link'

class StaffMembers extends Component {

  _renderTeam = (el, i) => {
    let image = el.acf.feature_image
    return (
      <Link to={`/team/${el.slug}`} key={i}>
        <Fade bottom distance='40px'>
          <picture>
            { image && <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} /> }
          </picture>
          <h4>{ el.acf.name }</h4>
          <h5>{ el.acf.job_title }</h5>
        </Fade>
      </Link>
    )
  }

  render() {

    return (
      <section className='module__staff-members'>
        <div className='module__staff-members__inner'>
          <h2>Team</h2>
        </div>
        <div className='module__staff-members__inner'>
          { this.props.team_members && this.props.team_members.map(this._renderTeam) }
        </div>
      </section>
    )
  }
}

export default StaffMembers
