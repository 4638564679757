import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class PulloutTextModule extends Component {
  render() {

    return (
      <section className='module__pullout-text'>
        <div className='module__pullout-text__inner'>
          <Fade bottom distance='40px'>
            <p dangerouslySetInnerHTML={{__html: this.props.first_column }}/>
            <p dangerouslySetInnerHTML={{__html: this.props.second_column }}/>
          </Fade>
        </div>
      </section>
    )
  }
}

export default PulloutTextModule
