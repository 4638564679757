import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'


import Link from '../utils/link'

class FlexibleFooterModule extends Component {

  _renderList = (el, i) => {
    return (
      <Fade bottom distance='40px' key={i}>
        <div>
          <h5>{ el.title }</h5>
          <ul>
            { el.items && el.items.map((item, j) => (
              <li key={j}>
                { item.url &&
                  <Link to={item.url}>{ item.text }</Link>
                }
                { !item.url &&
                  <span>{ item.text }</span>
                }
              </li>
            )) }
          </ul>
        </div>
      </Fade>
    )
  }

  render() {
    // TODO: split columns into 4 equal arrays

    let cols = [[], [], [], []]
    this.props.lists && this.props.lists.forEach((el, i) => {
      cols[i % 4].push(el)
    })

    return (
      <section className='module__flexible-footer'>
        <div className='module__flexible-footer__inner'>
          { cols.map((list, i) => (<div key={i}>{ list.map(this._renderList) }</div>)) }
        </div>
      </section>
    )
  }
}

export default FlexibleFooterModule
