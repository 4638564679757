import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ImageTextModule extends Component {
  render() {
    return (
      <Fade bottom distance='40px'>
        <section className={`module__text-image module__text-image--${this.props.side}`}>
          <div className='module__text-image__inner'>
            <picture>
              { this.props.image && <img src={this.props.image.localFile.childImageSharp.original.src} alt={this.props.image.alt_text} /> }
            </picture>
            <p dangerouslySetInnerHTML={{__html: this.props.content}} />
          </div>
        </section>
      </Fade>
    )
  }
}

export default ImageTextModule
