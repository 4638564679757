import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Services extends Component {

  _renderService = (el, i) => {
    return (
      <Fade bottom distance='40px' key={i}>
        <div className='module__services__item'>
          <h5>{ el.title }</h5>
          <p dangerouslySetInnerHTML={{__html: el.content }} />
        </div>
      </Fade>
    )
  }

  render() {
    return (
      <section className='module__services'>
        <div className='module__services__inner'>
          <Fade bottom distance='40px'>
            <h2>{ this.props.title }</h2>
          </Fade>
          <div className='module__services__content'>
            { this.props.services && this.props.services.map(this._renderService) }
          </div>
        </div>
      </section>
    )
  }
}

export default Services
