import React, { Component } from 'react'
import Slider from 'react-slick'
import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import Fade from 'react-reveal/Fade'


class SlideshowModule extends Component {
  state = {
    activeSlide: 0
  }

  _goToSlide(e, i) {
    e.preventDefault()
    this.slider.slickGoTo(i)
  }

  render() {
    let { activeSlide } = this.state

    const data = this.props

    const settings = {
      infinite: true,
      speed: 500,
      autoplay: false,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: true,
      centerMode: true,
      centerPadding: '0px',
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      responsive: [
        {
          breakpoint: 900,
          arrows: false,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '0px',
          }
        },
      ]
    }

    return (
      <Fade bottom distance='40px'>
        <section className='module__slideshow container'>
          <div className="module__slideshow--arrows">
            <a className="previous-arrow" href='//' onClick={(e) => this._goToSlide(e, activeSlide - 1)}><img src={prev} alt="Previous"/></a>
            <a className="next-arrow" href='//' onClick={(e) => this._goToSlide(e, activeSlide + 1)}><img src={next} alt="Next"/></a>
          </div>
          <div className='module__slideshow__inner'>
            <Slider className="module__slideshow__carousel" ref={slider => (this.slider = slider)} {...settings}>
              { data.images.map((el, i) => (
                <div key={i} className="module__slideshow-card">
                  <div className="module__slideshow-card__inner">
                    <img src={el.localFile.childImageSharp.original.src} alt={el.alt_text}/>
                  </div>
                </div>
              )) }
            </Slider>
          </div>
        </section>
      </Fade>
    )
  }
}

export default SlideshowModule
