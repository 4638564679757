import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class DescTextModule extends Component {
  render() {
    return (
      <section className='module__desctext'>
        <div className='module__desctext__inner'>
          <Fade bottom distance='40px'>
            <h2>{ this.props.primary_text }</h2>
          </Fade>
          <Fade bottom distance='40px'>
            <p>{ this.props.secondary_text }</p>
          </Fade>
        </div>
      </section>
    )
  }
}

export default DescTextModule
