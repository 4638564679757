import React, { Component } from 'react'
//import Fade from 'react-reveal/Fade'

class FormText extends Component {
  render() {
    return (
      <section className={`module__text-form`}>
        <div className='module__text-form__inner'>
          <div className='module__text-form__primary-content' dangerouslySetInnerHTML={{__html: this.props.primary_content}} />
          <div className='module__text-form__secondary-content' dangerouslySetInnerHTML={{__html: this.props.secondary_content}} />
        </div>
      </section>
    )
  }
}

export default FormText
