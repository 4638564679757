import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ModuleImageColumns extends Component {

  _renderImage = (el, i) => {
    if (!el.image) return null

    return (
      <Fade bottom distance='40px' key={i}>
        <picture className={`size-${el.size}`}>
          <img src={el.image.localFile.childImageSharp.original.src} alt={el.image.alt_text} />
        </picture>
      </Fade>
    )
  }

  render() {
    return (
      <section className='module__image-columns'>
        <div className='module__image-columns__inner'>
          { this.props.images && this.props.images.map(this._renderImage) }
        </div>
      </section>
    )
  }
}

export default ModuleImageColumns
