import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import Link from '../utils/link'

class Expertise extends Component {

  _renderField = (el, i) => {
    let title = this.props.data.expertise.edges.find((exp) => exp.node.wordpress_id === el.expertise)
    return (
      <Fade bottom distance='40px' key={i}>
        <div className='module__expertise__item'>
          <picture>
            <img src={el.image.localFile.childImageSharp.original.src} alt={el.image.alt_text} />
          </picture>
          <h3>{ he.decode(title.node.name) }</h3>
          <p dangerouslySetInnerHTML={{__html: el.content }} />
          <Link to={`/projects?filter=${title.node.wordpress_id}`}>View Projects</Link>
        </div>
      </Fade>
    )
  }

  render() {
    return (
      <section className='module__expertise'>
        <div className='module__expertise__inner'>
          <Fade bottom distance='40px'>
            <h2>Expertise</h2>
          </Fade>
          <div className='module__expertise__fields'>
            { this.props.fields && this.props.fields.map(this._renderField) }
          </div>
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        expertise: allWordpressWpExperise {
          edges {
            node {
              wordpress_id
              name
              slug
            }
          }
        }
      }
    `}
    render={data => <Expertise data={data} {...props} />}
  />
)
