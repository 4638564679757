import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import Link from '../utils/link'

class ProjectsArchive extends Component {

  state = {
    filter: null,
    description: this.props.intro_text
  }

  componentDidMount() {
    let url = new URL(window && window.location.href)
    let filter = parseInt(url.searchParams.get("filter"))
    if (!isNaN(filter)) this.setState({ filter })
  }

  _renderFilter = (el, i) => {
    let { filter, description } = this.state
    let onClick = () => {
      if (filter !== el.node.wordpress_id) {
        filter = el.node.wordpress_id
        description = el.node.description
      } else {
        filter = null
      }
      this.setState({ filter, description })
    }

    let props = {
      key: i,
      onClick: onClick,
      onKeyDown: onClick,
      className: filter === el.node.wordpress_id ? 'active' : '',
    }

    return (
      <li {...props}>{ he.decode(el.node.name) }</li>
    )
  }

  _renderProject = (el, i) => {
    let { filter } = this.state
    let className = 'module__projects-archive__project'

    if (filter !== null) {
      if (el.node.experise.includes(filter) || el.node.service.includes(filter)) {
        className += ' active'
      } else {
        return null
      }
    } else {
      className += ' active'
    }

    return (
      <Link className={className} to={`/project/${el.node.slug}`} key={i}>
        <Fade bottom distance='40px'>
          <picture>
            { el.node.acf.feature_image &&
              <img src={el.node.acf.feature_image.localFile.childImageSharp.original.src} alt={el.node.acf.feature_image.alt_text} />
            }
          </picture>
          <h4>Project</h4>
          <h3>{ el.node.title }</h3>
        </Fade>
      </Link>
    )
  }

  render() {
    return (
      <section className='module__projects-archive'>
        <div className='module__projects-archive__inner'>
          <div className='module__projects-archive__row'>
            <div className='module__projects-archive__intro'>
              <Fade bottom distance='40px'>
                <p ref='description' dangerouslySetInnerHTML={{__html: this.state.description }} />
              </Fade>
            </div>
            <div className='module__projects-archive__filters'>
              <Fade bottom distance='40px'>
                <div>
                  <h4>Filter by expertise</h4>
                  <ul>
                    { this.props.data.expertise.edges.map(this._renderFilter) }
                  </ul>
                </div>
                <div>
                  <h4>Filter by service</h4>
                  <ul>
                    { this.props.data.services.edges.map(this._renderFilter) }
                  </ul>
                </div>
              </Fade>
            </div>
          </div>
          <div className='module__projects-archive__row'>
            { this.props.data.projects.edges.map(this._renderProject) }
          </div>
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        projects: allWordpressWpProject(sort: {fields: menu_order, order: ASC}, filter: {slug: {ne: "example-project-do-not-delete"} }) {
          edges {
            node {
              slug
              title
              experise
              service
              acf {
                feature_image {
                  ...original
                }
              }
            }
          }
        }
        services: allWordpressWpService {
          edges {
            node {
              wordpress_id
              name
              slug
              description
            }
          }
        }
        expertise: allWordpressWpExperise {
          edges {
            node {
              wordpress_id
              name
              slug
              description
            }
          }
        }
      }
    `}
    render={data => <ProjectsArchive data={data} {...props} />}
  />
)
