import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class LargeCenterText extends Component {
  render() {
    return (
      <section className='module__large-center-text'>
        <div className='module__large-center-text__inner'>
          <Fade bottom distance='40px'>
            <p dangerouslySetInnerHTML={{__html: this.props.text }} />
          </Fade>
        </div>
      </section>
    )
  }
}

export default LargeCenterText
